import api from './api'

const url = 'collaborator'

const findAllCollaborators = () => api.get('collaborator')

const getContact = (login) => api.get(`${url}/contact?login=${login}`)

const updateContact = params => api.post(`${url}/update`, params)

export default { findAllCollaborators, getContact, updateContact }
