<template>
  <v-container fluid class="pa=0 ma-0">
    <v-row>
      <carousel />
      <v-col
        xs="12"
        sm="7"
        md="9"
        align-self="center"
        class="pa=0 ma-0"
      >
        <session-title
          class="pt-8 px-8 pb-0"
          title="Cadastro de Elogio"
          description="Utilize a criatividade para elogiar um companheiro de trabalho 🤗."
        />
      </v-col>
      <v-col
        class="pt-0 px-11 pb-3"
        sm="5"
        md="3"
        align-self="center"
      >
        <chat-ti-modal-access-vue />
      </v-col>

      <v-form
        ref="form"
        class="pt-8 px-8 pb-0"
      >
        <v-row
          justify="start"
          no-gutters
        >
          <v-col
            class="d-flex pr-5"
            lg="12"
            md="12"
            sm="12"
          >
            <v-autocomplete
              v-model="compliment.recipient"
              :items="listCollaborator"
              item-text="name"
              item-value="user"
              label="Nome do Colaborador *"
              outlined
              return-object
              :rules="$rules.required"
              required
            />
          </v-col>

          <v-container
            class="px-0"
            fluid
          >
            <v-checkbox
              v-model="nameless"
              label="Anônimo"
            />
          </v-container>

          <v-col
            class="pr-5"
            lg="12"
            md="12"
            sm="12"
          >
            <v-textarea
              v-model="compliment.text"
              label="Texto *"
              :rules="$rules.required"
              outlined
              required
            />
          </v-col>

          <v-col>
            <v-btn
              class="mr-2"
              color="primary"
              @click="clearForm"
            >
              Cancelar
            </v-btn>
            <v-btn
              v-if="loading === true"
              color="secondary"
              loading
              @click="submit"
            />
            <v-btn
              v-else
              color="secondary"
              @click="submit"
            >
              Enviar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
  </v-container>
</template>

<script>
import SessionTitle from "../../components/SessionTitle.vue";
import Carousel from "@/components/Carousel";
import Collaborator from "../../services/collaborator-service";
import Compliment from "../../services/compliment-service";
import ChatTiModalAccessVue from "../../components/ModalViews/ChatTiModalAccess.vue";

export default {
  name: "Vacation",
  components: {
    carousel: Carousel,
    SessionTitle,
    Carousel,
    ChatTiModalAccessVue
  },
  data() {
    return {
      nameless: false,
      loading: false,
      compliment: {
        text: null,
        recipient: null,
        user: "Anônimo"
      },
      listCollaborator: []
    };
  },
  async created() {
    this.listCollaborator = await Collaborator.findAllCollaborators();
  },
  methods: {
    clearForm() {
      this.$refs.form.reset();
    },
    async submit() {
      if (!this.compliment.text || !this.compliment.recipient) {
        this.$toast.error("Verifique os campos e tente novamente");
        return;
      }

      try {
        this.loading = true;
        if (!this.nameless) {
          this.compliment.user = window.localStorage.getItem("cassems_user");
        }
        this.compliment.recipient = this.compliment.recipient.name;
        await Compliment.createCompliment({ ...this.compliment });
        this.$toast.success("Elogio enviado com sucesso!");
        this.clearForm();
      } catch (error) {
        console.log(error);
        this.$toast.error("Erro ao enviar elogio!");
      }
      this.loading = false;
    }
  }
};
</script>
